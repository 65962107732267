<template>

  <div class="row" ref="projectForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Project Name"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              vid="project_id"
              rules="required"
              name="The Project ID"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Project ID"
                        name="project_id"
                        fou
                        v-model="formData.project_id">
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              vid="client_id"
              rules=""
              name="The Client"
              v-slot="{ passed, failed,errors }">
              <fg-select
                name="client_id"
                size="large"
                filterable
                clearable
                placeholder="Client"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Client'"
                :list="clientList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                @change="getCampaing(client_id)"
                v-model="client_id">
              </fg-select>
            </ValidationProvider>
            <ValidationProvider
              vid="campaign_id"
              rules="required"
              name="The Campaign"
              v-slot="{ passed, failed,errors }">
              <fg-select
                name="campaign_id"
                size="large"
                filterable
                clearable
                placeholder="Campaign"
                :error="failed ? errors[0]: null"
                :input-classes="'select-default'"
                :label="'Campaign'"
                :list="campaignList"
                :listItemLabel="'name'"
                :listItemValue="'id'"
                v-model="formData.campaign_id">
              </fg-select>
            </ValidationProvider>


            <ValidationProvider
              vid="product_title"
              rules="required"
              name="The Product Title"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Product Title"
                        name="product_title"
                        fou
                        v-model="formData.product_title">
              </fg-input>
            </ValidationProvider>

            <div class="form-group">
              <label>Details</label>
              <editor
                v-model="formData.product_details"
                :api-key="editorKey"
                :init='editorConfig'
              />
            </div>

            <div class="form-group">
              <label>Image</label>
              <el-tooltip placement="right" v-if="getBannerImageInfo()">
                <div slot="content">{{getBannerImageInfo()}}</div>
                <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
              </el-tooltip>
              <prime-uploader
                :preview-width="'200px'"
                :preview-height="'200px'"
                :elementNum="1"
                v-model="formData.product_image"
              >
              </prime-uploader>
            </div>

            <h5>Parcel Information</h5>
            <div class="row">
              <div class="col-md-3 col-sm-12">
                <ValidationProvider
                  vid="parcel_dimension_width"
                  rules="required"
                  name="The Parcel Width"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="'Parcel Width (less than '+parcel_dimension_width_allowed+' IN)'"
                            name="parcel_dimension_width"
                            @change="calculateGirth"
                            @keypress="calculateGirth"
                            fou
                            v-model="formData.parcel_dimension_width">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-3 col-sm-12">
                <ValidationProvider
                  vid="parcel_dimension_length"
                  rules="required"
                  name="The Parcel Length"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="'Parcel Length (less than '+parcel_dimension_length_allowed+' IN)'"
                            name="parcel_dimension_length"
                            fou
                            v-model="formData.parcel_dimension_length">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-3 col-sm-12">
                <ValidationProvider
                  vid="parcel_dimension_height"
                  rules="required"
                  name="The Parcel Height"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            :label="'Parcel Height (less than '+parcel_dimension_height_allowed+' IN)'"
                            name="parcel_dimension_height"
                            @change="calculateGirth"
                            @keypress="calculateGirth"
                            fou
                            v-model="formData.parcel_dimension_height">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-3 col-sm-12">
                <label class="card-label">Girth</label>
                <el-tooltip placement="right" >
                  <div slot="content">( Girth + Length must be less than 109)</div>
                  <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                </el-tooltip>
                <fg-input type="number"
                          disabled="true"
                          name="girth"
                          fou
                          v-model="girth">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <label class="card-label">Parcel Weight(less than 1120 OZ)</label>
                <el-tooltip placement="right">
                  <div slot="content">First-class Mail if less than 16.<br> Priority mail if more than 16</div>
                  <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                </el-tooltip>
                <ValidationProvider
                  vid="parcel_weight"
                  rules="required"
                  name="The Parcel Weight"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            name="parcel_weight"
                            fou
                            @keypress="changeLabelType"
                            @change="changeLabelType"
                            @keyup="changeLabelType"
                            v-model="formData.parcel_weight">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6 col-sm-12">
                <ValidationProvider
                  vid="label_type"
                  rules="required"
                  name="The Label Type"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="label_type"
                    size="large"
                    filterable
                    clearable
                    @change="changeParcelValidationMessages"
                    placeholder="Label Type"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Label Type'"
                    :list="labelTypeList"
                    :listItemLabel="'label'"
                    :listItemValue="'value'"
                    :disabled="formData.parcel_weight > 16"
                    v-model="formData.label_type">
                  </fg-select>
                </ValidationProvider>
              </div>

            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit(true))"
                      nativeType="submit"
                      type="default" wide>Save
            </l-button>
            <l-button @click="$router.push('/projects/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "../../components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';
import {Tooltip} from 'element-ui';

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {FgSelect,
    FormGroupTextArea,
    PrimeUploader,
    PrimeVideoUploader,
    [Tooltip.name]: Tooltip,
    'editor': Editor,},
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      editorConfig: this.getEditorConfig(),
      formData: {
        name: "",
        project_id: "",
        qr_code: "",
        campaign_id: "",
        product_title: '',
        product_image: '',
        product_details: '',
        parcel_weight_unit_of_measurement: 'OZ',
        parcel_weight: 0,
        parcel_dimension_unit_of_measurement: 'IN',
        parcel_dimension_length: 0,
        parcel_dimension_width: 0,
        parcel_dimension_height: 0,
        label_type: '',
      },
      client_id: "",
      clientList: [],
      campaignList: [],
      parcelWeightTypeList: [],
      parcelDimensionTypeList: [],
      labelTypeList: [],
      girth: 0,
      parcel_dimension_width_allowed: '',
      parcel_dimension_height_allowed: '',
      parcel_dimension_length_allowed: '',


    };
  },
  watch: {
    client_id: {
      handler: function (newValue) {
        if (this.client_id === ""){
          this.formData.campaign_id = "";
        }
        let data ={
          client_id: this.client_id
        }
        this.axios.post("projects/builder", data).then((response) => {
          this.campaignList = response.data.campaigns;
        }).catch((error) => {
          console.error(error);
        })
      },
      deep: true
    },
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.projectForm
    });

    this.axios.post("projects/builder").then((response) => {
      this.clientList = response.data.clients;
      this.parcelWeightTypeList = response.data.parcelWeightType;
      this.parcelDimensionTypeList = response.data.parcelDimensionType;
      this.labelTypeList = response.data.labelType;
      this.id = this.$route.params['id'];
      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Project";
        this.getProject();

      } else {
        this.editMode = false;
        this.formTitle = "Add Project";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },
  methods: {
    getCampaing(client_id){
      this.formData.campaign_id = '';
      let data ={
        client_id: client_id
      }
      this.axios.post("projects/builder", data).then((response) => {
        this.campaignList = response.data.campaigns;
      }).catch((error) => {
        console.error(error);
      })
    },
    getProject() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("projects/get", data).then((response) => {
        this.formData = response.data;
        this.client_id = response.data.campaign.client_id;
        this.getCampaing(this.client_id)
        this.formData.campaign_id =  response.data.campaign.id;
        this.formData.parcel_weight_unit_of_measurement = 'OZ';
        this.formData.parcel_dimension_unit_of_measurement = 'IN';
        this.calculateGirth();
        this.changeParcelValidationMessages();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: error.response.data.message,
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        }
        else {
          console.error(error);
        }
      })
    },

    submit(isSave = false) {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("projects/update/" + this.id, this.formData);
        successMessage = "Project Updated Successfully";
      } else {
        request = this.axios.post("projects/create", this.formData);
        successMessage = "Project Added Successfully";
      }
      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        if (isSave){
          window.location.href = "/projects/edit/" + response.data.id;
        }else{
          this.$router.push("/projects/list");
        }
      }).catch((error) => {

        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
          this.submitting = false;
          this.entityNotFoundError = false;
        } else {
          if(error.response.data && error.response.data.message){
            this.$notify({
              message: error.response.data.message,
              timeout: 4000,
              type: 'danger'
            });
          }
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["news_image"]) {
        return this.mediaInfo["news_image"];
      } else {
        return "";
      }
    },
    calculateGirth(){
      this.girth = 2 * (parseInt(this.formData.parcel_dimension_height) + parseInt(this.formData.parcel_dimension_width));
    },
    changeLabelType(){
      let parcelWeight = Number(this.formData.parcel_weight);
      if (this.countDecimals(parcelWeight) > 2){
        this.formData.parcel_weight = parcelWeight.toFixed(2);
      }
      if (this.formData.parcel_weight < 16){
        this.formData.label_type = 'FCM';
      }else{
        this.formData.label_type = 'PM';
      }
      this.changeParcelValidationMessages();

    },
    countDecimals(value) {
      let text = value.toString()
      // verify if number 0.000005 is represented as "5e-6"
      if (text.indexOf('e-') > -1) {
        let [base, trail] = text.split('e-');
        let deg = parseInt(trail, 10);
        return deg;
      }
      // count decimals for number in representation like "0.123456"
      if (Math.floor(value) !== value) {
        return value.toString().split(".")[1].length || 0;
      }
      return 0;
    },
    changeParcelValidationMessages(){
      if(this.formData.label_type === 'PM'){
        this.parcel_dimension_width_allowed = 30;
        this.parcel_dimension_height_allowed = 34;
        this.parcel_dimension_length_allowed = 98;
      }else{
        this.parcel_dimension_width_allowed = 15;
        this.parcel_dimension_height_allowed = 18;
        this.parcel_dimension_length_allowed = 22;
      }
    }

  }
}
</script>

<style>
</style>
